export const pages = [
  {
    route: `/`,
    title: "Inicio",
  },
  {
    route: `/dulces`,
    title: `Dulces`,
  },
  {
    route: `#`,
    title: `Pedir`,
  },
  {
    route: `/contactar`,
    title: `Contactar`,
  },
];
