import React from "react";
import {FaFacebook, FaWhatsapp} from "react-icons/fa";
import PropTypes from "prop-types"

function Address(props) {
    return (
        <div className={props.style ? props.style : `flex flex-col md:flex-row`}>
            <div className="px-2">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-sm">
                    ADDRESS
                </h2>
                <p className="leading-relaxed text-gray-700">
                    34, Calle Dr. Luis Prieto, 32, 30880 Águilas, Murcia
                </p>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-sm mt-4">
                    SOCIAL
                </h2>
                <div className="text-2xl">
                    <a
                        href="https://www.facebook.com/missdulcesaguilas"
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-700 inline-block mr-4"
                    >
                        <FaFacebook/>
                    </a>
                    <a
                        href="https://wa.me/+34678927989"
                        target="_blank"
                        rel="noreferrer"
                        className="text-green-700 inline-block"
                    >
                        <FaWhatsapp/>
                    </a>
                </div>
            </div>
            <div className="px-2 mt-4 md:mt-0">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-sm">
                    EMAIL
                </h2>
                <a
                    href="mailto: pasteleriamissdulces@yahoo.es"
                    className="text-indigo-500 leading-relaxed"
                >
                    pasteleriamissdulces@yahoo.es
                </a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-sm mt-4">
                    PHONE
                </h2>
                <a
                    className="block leading-relaxed text-indigo-500"
                    href="tel:+34678927989"
                >
                    <span>678 92 79 89</span>
                </a>
            </div>
        </div>
    );
}

Address.propTypes = {
    style: PropTypes.string
}

export default Address;