import PropTypes from "prop-types";
import React from "react";
import CookieConsent from 'react-cookie-consent';
import Cookies from "js-cookie";

import Header from "./header";
import Footer from "./footer";

function Layout({children, isInputFocused, isBigLogo}) {
    return (
        <div className="holder mb-12 md:mb-0">
            <Header isBigLogo={isBigLogo} isInputFocused={isInputFocused}/>
            <>{children}</>
            <Footer/>
            <CookieConsent
                location="bottom"
                buttonText="Lo entiendo"
                enableDeclineButton
                declineButtonText="Rechazar"
                cookieName="gatsby-gdpr-google-analytics"
                disableStyles
                containerClasses="fade-in-bottom fixed bg-myGrayDark border-2 shadow-2xl rounded-lg p-5 mx-2 mb-24 max-w-xl md:mb-8 md:ml-8"
                contentClasses="text-white"
                buttonClasses="bg-green-600 hover:bg-myViolet text-white p-2 m-2 rounded border"
                declineButtonClasses="bg-transparent hover:bg-myViolet text-white p-2 m-2 ml-0 rounded border"
                onAccept={() => {
                    Cookies.set("gatsby-gdpr-google-analytics", "true");
                }}
                onDecline={() => {
                    Cookies.set("gatsby-gdpr-google-analytics", "false");
                }}>
                Utilizamos cookies para personalizar el contenido y analizar nuestro tráfico.
            </CookieConsent>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    isInputFocused: PropTypes.bool,
    isBigLogo: PropTypes.bool
};

export default Layout;
