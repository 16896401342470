import {useStaticQuery, graphql} from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import {Helmet} from "react-helmet";

function SEO({description, lang, meta, pageKeywords, title}) {
    const {site} = useStaticQuery(graphql`
        query DefaultSEOQuery {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `);

    const metaDescription = description || site.siteMetadata.description;
    const defaultKeywords = `pasteleria miss dulces, miss dulces, pasteleria, pasteleria en aguilas, pasteles en aguilas, dulces, pasteles, cafeteria, panaderia, aguilas, lorca, murcia, tartas`;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: "google-site-verification",
                    content: "VdA6NPOGAKclZME2Sd-O3jvU_ECpQeAyieQvf1nXhG8",
                },
            ]
                .concat(
                    pageKeywords
                        ? {
                            name: `keywords`,
                            content: `${defaultKeywords}, ${pageKeywords.join(", ")}`,
                        }
                        : {
                            name: `keywords`,
                            content: defaultKeywords
                        }
                )
                .concat(meta)}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
        />
    );
}

SEO.defaultProps = {
    lang: `es`,
    keywords: [],
    meta: [],
};

SEO.propTypes = {
    description: PropTypes.string,
    pageKeywords: PropTypes.arrayOf(PropTypes.string),
    lang: PropTypes.string,
    meta: PropTypes.array,
    title: PropTypes.string.isRequired,
};

export default SEO;
