import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Address from "./contactInfo/address";

const Footer = () => {
  const {
    file: {
      childImageSharp: { fixed },
    },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-miss-dulces-maskable.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <footer className="container px-5 py-8 mx-auto text-gray-700 body-font">
      <Address style="flex flex-col justify-center items-center text-center mb-6 md:flex-row md:items-start md:justify-between md:text-left"/>
      <div className="flex items-center sm:flex-row flex-col">
        <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          <Img className="rounded-lg w-12" fixed={fixed} />
          <span className="ml-3 text-xl">pasteleriamissdulces</span>
        </a>
        <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © 2020 pasteleriamissdulces
        </p>
        <a
          href="https://github.com/edustef"
          className="text-gray-500 text-xs ml-0 mt-2 md:mt-0 md:ml-auto"
          rel="noopener noreferrer"
          target="_blank"
        >
          Website made by @edustef
        </a>
      </div>
    </footer>
  );
};

export default Footer;
