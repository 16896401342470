import { Link } from "gatsby";
import React, { useEffect } from "react";
import { pages } from "../data/pages";
import logo from "../images/logo-miss-dulces.svg";
import { FaHome, FaPhone, FaBirthdayCake, FaWpforms } from "react-icons/fa";
import PropTypes from "prop-types";

function toggleHeader(isInputFocused) {
  if (window.matchMedia("(max-width: 768px)").matches && isInputFocused) {
    document.querySelector(".navbar").style.display = "none";
  } else {
    document.querySelector(".navbar").style.display = "flex";
  }
}

function Header(props) {
  const iconComponents = {
    inicio: FaHome,
    contactar: FaPhone,
    dulces: FaBirthdayCake,
    pedir: FaWpforms,
  };

  useEffect(() => {
    toggleHeader(props.isInputFocused);
  });

  return (
    <header className="header flex m-0 flex-col items-center md:items-start md:flex-row md:justify-start">
      <Link
        to="/"
        className="mt-6 mx-0 md:mx-16 lg:mx-32 xl:mx-32 w-32 lg:w-40"
      >
        <img className={props.isBigLogo ? "" : "logo-alt"} src={logo} alt="" />
      </Link>
      <nav
        aria-label="navigation"
        role="navigation"
        className="navbar fixed px-0 py-1 inset-x-0 bottom-0 shadow-inner bg-myPinkLight flex justify-between md:mt-12 z-10"
      >
        {pages.map((link) => {
          const Icon = iconComponents[link.title.toLowerCase()];
          return (
            <Link className="w-full justify-center inline-block text-center pt-2 pb-1 focus:text-myViolet hover:text-myViolet" key={link.title} to={link.route}>
              <Icon className="inline-block mb-1" />
              <span className="block text-xs">{link.title}</span>
            </Link>
          );
        })}
      </nav>
    </header>
  );
}

Header.propTypes = {
  isBigLogo: PropTypes.bool,
  isInputFocused: PropTypes.bool,
};

export default Header;
